@font-face {
    font-family: 'Mulish';
    font-weight: 900;
    font-style: normal;
    src: url('../fonts/mulish/Mulish-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/inter/Inter-Regular.ttf') format('truetype');
}

// Apply Mulish font to specific elements
.display-font {
    font-family: 'Mulish';
    font-weight: 900;
}

.headline-font {
    font-family: 'Mulish';
    font-weight: 900;
    font-size: 38px;
}

.subheading-font {
    font-family: 'Mulish';
    font-size: 24px;
    font-weight: 800;
}

.title-font {
    font-family: 'Mulish';
    font-weight: 900;
    font-size: 32px;
}

.body-font {
    font-family: 'Inter';
    font-weight: normal;
}

.skeleton-title-font {
    font-family: 'Mulish';
    font-weight: 500;
}

.MuiAutocomplete-root  {
    font-family: 'Inter' !important;

    .MuiAutocomplete-option {
        font-family: 'Inter' !important;
    }
   
}

.MuiAutocomplete-listbox,
.MuiFormControlLabel-root,
.MuiTypography-root,
.MuiInputBase-root,
.MuiButtonBase-root,
.box-content,
.MuiOutlinedInput-root,
.MuiTableCell-head,
.MuiTableRow-head,
.MuiTableCell-root,
.Slider-content
.footer-link,
.footer-copyrights,
.footer-grid-title,
.footer-grid,
body,
.mantine-Text-root {
    font-family: 'Inter' !important;

    a {
        font-family: 'Inter' !important;
        color: black;
        text-decoration: none;
    }
}

// @media (max-width: 767px) {
//     .headline-font {

//     }
// }

@media (max-width: 500px) {
    .personal-feilds {
        .headline-font {
            font-size: 26px;
        }
    }
   
    .subheading-font {
        font-size: 20px;
    }
    .skeleton-title-font {
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    .headline-font {
        font-size: 30px;
    }
}