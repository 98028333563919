.services-home {
    display: grid;
    gap: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.services-home-header {
    width: 100%;
    display: grid;
    gap: 15px;
    text-align: start;

    .text-second {
        color: #545454 !important;
    }
}

.services-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;

    .services-card {
        padding: 30px;
        background-color: #f3f6fb;
        border-radius: 0.5rem;
        box-shadow: 0 0.3125rem 1.5rem 0 rgba(34, 29, 31, .02);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .services-icon {
        padding: 10px;
        font-size: 24px;
        border-radius: 0.5rem;
        background-color: #008cff28;
    }
}

@media (max-width: 767px) {
    .services-list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 20px;
    }

    .services-list .services-card {
        padding: 20px;
    }
}