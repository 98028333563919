.about-home-body {
    display: flex;
    padding-top: 50px;
    padding-bottom: 30px;
    gap: 80px;
    align-items: center;
    
    .about-home-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        width: 100%;
    }

    .about-home-image {
        width: 80%;
        height: 100%;
         
        img {
            height: 100%;
        }
    }

    .MuiButtonBase-root {
        margin-top: 5px;
    }
}

@media (max-width: 767px) {
    .about-home-body {
        padding-top: 0;
        flex-direction: column;
        gap: 40px;
    }

    .about-home-body .about-home-image {
        width: 100%;
    }
}