.techs-home {
    display: grid;
    gap: 30px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.techs-home-header {
    width: 100%;
    display: grid;
    gap: 15px;
    text-align: center;

    .text-second  {
        color: #545454 !important;
    }
}

.techs-home-list {
    // margin-top: 10px;
    // padding: 0 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 60px;
    justify-content: center;

    .mantine-Image-root {
        flex-grow: 1;
        width: auto !important;
        .mantine-Image-figure {
            .mantine-Image-imageWrapper {
                .mantine-Image-image {
                    width: 95px !important;
                    object-fit: contain !important;
                    height: 95px !important;
                }
            }
        }
    }
}

.techBox {
    display: grid;
    gap: 20px;
    justify-items: center;
}

@media (max-width: 767px) {
    .techs-home-list .mantine-Image-root .mantine-Image-figure .mantine-Image-imageWrapper .mantine-Image-image {
        width: 70px !important;
        height: 70px !important;
    }
}