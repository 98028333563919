.slider-main {
    position: relative;

    .slick-dots {
        bottom: 15px !important;
    }
    
    .slick-dots li button:before {
        font-size: 15px !important;
    }
    
}

.slider-body {
    display: block !important;
}

.slider-img {
    height: 400px;
    width: 100%;
    object-fit: cover;
}

.slider-content {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    top: 0;
    color: #fff;
    z-index: 2;
    align-items: flex-start;

    h3 {
        margin: 0 173px;
    }
}

@media (max-width: 767px) {

    .slider-img {
        height: 340px;
    }

    .slider-content h3 {
        margin: 0;

        padding: 0 30px;
    }
}