.footer-section {
    padding-top: 40px;
    display: grid;
    gap: 30px;
}

.footer-section-page {
    background: #f3f6fb;
}

.footer-body {
    display: flex;
    justify-content: space-between;
    gap: 10%;
}

.footer-logo {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;

    img {
        height: 40px;
    }
}

.footer-links-list {
    display: flex;
    gap: 100px;
}

.footer-grid {
    display: grid;
    gap: 10px;

    .footer-grid-title  {
        font-weight: 600;
    }

    .footer-grid-links {
        display: grid;
        gap: 10px;
    }

    .footer-link {
        font-size: 14px;
    }
}

.footer-copyrights {
    display: flex;
    justify-content: space-between;
    gap: 10%;
    padding: 15px 0;
    border-top: 1px solid #dee2e6;
}

.dm-footer {
    .footer-copyrights {
        justify-content: center;
    }
}

@media (max-width: 767px) {
    .footer-body {
        flex-direction: column;
        gap: 30px;
    }

    .footer-logo {
        align-items: center;
    }

    .footer-links-list {
        flex-direction: column;
        gap: 40px;

        .footer-grid {
            justify-items: center;
            text-align: center;
        }
    }

    .footer-copyrights {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 5px;
    }
}