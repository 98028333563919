.about-section {
    width: 100%;
}

.about-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 50px;
    padding-bottom: 140px;
    min-height: 250px;
}

.about-slider {
    img {
        width: 100% !important;
    }
}

.comingsoon-img {
    img {
        object-fit: contain !important;
        height: 400px !important;
    }
}