.navmenu-main {
    width: 100%;
    position: relative;
    height: 100px;
}

.personal-header {
    border-bottom: 1px solid #eee;

    button {
        color: black !important;
    }

    a {
        color: black !important;
    }

    .navmenu {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        vertical-align: middle;
        height: auto;
        width: 100%;

        .logo {
            height: auto;

            img {
                min-height: 45px;
                height: 45px;
                box-sizing: content-box;
                width: auto;
                padding: 25px 0;
            }
        }

        .nav-link {
            text-decoration: none;
            font-size: 16px;
            font-family: 'Inter' !important;
            color: black !important;
        }

        .active {
            font-weight: 699;
        }
    }

    .nav-sublink {
        text-decoration: none !important;
        font-family: 'Inter' !important;
        color: black !important;

        a {
            text-decoration: none !important;
            font-family: 'Inter' !important;
            color: black !important;
        }
    }
}

.personal-header-scrolled {
    position: fixed;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 90;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
    .navmenu-main {
        height: auto !important;
    }
    
    .personal-header {
        .navmenu {
            gap: 60px;

            .logo {
                width: 200px;
                height: auto;

                img {
                    min-height: 35px;
                    height: 35px;
                    box-sizing: content-box;
                    width: 100% !important;
                    padding: 25px 0;
                }
            }
        }
    }
}


    .mobilemenu {
        display: flex;
        gap: 20px;
        flex-direction: column;
    }
