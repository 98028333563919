.page-body {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 50px;
    padding-bottom: 140px;
    min-height: 250px;
}

.page-section {
    position: relative;
    width: 100%;
    // background-color: #f3f6fb;
}