.buttons {
    display: flex;
    gap: 15px;
}

.buttons-2 {
    display: flex;
    margin-top: 15px;
    gap: 15px;
    margin-bottom: 25px;
}

.support-btn {
    button {
        line-height: normal;
        color: #3399FF;
    }
}

.search-button {
    svg {
        color: #000;
    }
}

.MuiButton-contained {
    position: relative;
    background-color: #0a253b !important;
    box-shadow: none !important;
    // border-radius: 1.5rem !important;

    // test
    text-transform: none !important;
    font-size: 16px !important;

    &:disabled {
        // background-color: #000066a8 !important;
        cursor: not-allowed;
    }
}

.MuiButton-contained:hover {
    background-color: #3399FF !important;
}

.MuiButton-outlined {
    border-color: #3399FF !important;
    color: #3399FF !important;
}

.MuiButton-contained:hover {
    background-color: #3399FF !important;
}

.dynamic-button {
    min-width: 80px !important;
    color: #fff !important;
    .MuiCircularProgress-root {
        color: #fff;
    }
}

.number-input {
  .MuiOutlinedInput-input,
  input {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    margin-right: 0;
  }
}
