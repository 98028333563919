.searchbar {
    width: 63%;
}

.personal-search {
    position: relative;

    .MuiInputBase-root {
        position: relative;
        display: flex;
        width: auto;
        background: #ffffff;
        padding: 2px 4px;
        border: 1px solid #d1d5db;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 6px;
        height: 50px;
        margin-left: 0;
        padding: 0 55px 0 15px;
    }

    .right-iconButton {
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            height: 44px;
        }

        .MuiButtonBase-root {
            transition: background-color 0.3s, color 0.2s;

            svg {
                transition: background-color 0.3s, color 0.2s;
            }
        }
    }

    .Mui-focused {
        border-color: #000066 !important;
        border-width: 2px;
    }
}

.search-body {
    .personal-form {
        width: 100%;
        justify-content: center;
        padding-right: 0;
    }
}

@media (max-width: 767px) {

    .searchbar {
        width: 80%;
    }

    .personal-search {
        .MuiInputBase-root {
            padding: 0 53px 0 15px;
        }

    }
}