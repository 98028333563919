.contact-body {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-top: 40px;
    padding-bottom: 60px;
    min-height: 250px;

    .MuiButton-contained {
        background-color: #49adff !important;
    }
}

.contact-form-main {
    display: grid;
    background: linear-gradient(135deg, #4dabf7 0, #228be6 100%);
    padding: 15px 60px;
    border-radius: 0.5rem;
    justify-items: end;
    padding-top: 40px;
}

.contact-form {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 60px;

    .mantine-InputWrapper-label {
        color: #fff !important;
    }

    .contact-form-bg {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        position: relative;
        flex-direction: column;
        padding-bottom: 3px;

        .contact-title {
            font-size: 42px;
            color: #fff;
            font-family: sans-serif !important;
            font-weight: 900 !important;
        }

        .bg-title {
            // position: absolute;
            color: #ffffff2e;
            font-size: 100px;
            // height: 100%;
            font-family: sans-serif !important;
            display: flex;
            align-items: center;
        }
    }

}

.contact-demo {
    width: 100%;
    background-color: #49adff !important;
    border-radius: 0.5rem;
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s;

    &:hover {
        background-color: #4dabf7 !important;
    }

    .demo-box {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        color: #0a253b;
        font-weight: 700;
        font-size: 20px;
        padding: 20px;
        border-radius: 10px;

        svg {
            width: 60px;
            height: 60px;
            fill: #0a253b;
        }

       
        
    }
}

.contact-card {
    height: rem(160px);
    position: relative;
    background-size: 100%;
    background-position: center;
    color: #fff;
    border-radius: 0.5rem;
    padding: 80px 30px;
    overflow: hidden;
    transition: background-size 300ms ease;

    &:hover {
        background-size: 105%;
    }
}

.contact-card-title {
    color: #fff;
    z-index: 2;
    position: relative;
}

.contact-banner {
    width: 100%;
}

@media (max-width: 767px) {
    .contact-form-main {
        padding: 15px 20px;
    } 

    .contact-form .contact-form-bg .bg-title {
        font-size: 48px;
        width: 100%;
    }

    .contact-form .contact-form-bg .contact-title {
        font-size: 28px;
    }

    .contact-form {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 40px;
    }

    .contact-demo {
        min-height: 240px;
    }
}