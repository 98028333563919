.home-section {
    position: relative;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    overflow: hidden;
}

.home-body {
    padding: 30px 0;
}

.banner-home{ 
    position: relative;

    .contact-slider-content {
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    .banner-text {
        display: flex;
        height: 100%;
        flex-direction: row;
        gap: 60px;
        justify-content: center;
        align-items: center;

        .MuiButton-contained {
            background-color: #008cff28 !important;
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {

}

@media (max-width: 767px) {
    .banner-home .banner-text {
        flex-direction: column;
        gap: 20px;
    }

    .banner-home {
        height: 260px;

        .mantine-Image-root {
            height: 100%;

            .mantine-Image-figure {
                height: 100% !important;
            }

            .mantine-Image-imageWrapper {
                height: 100%;
            }

            img {
                height: 100% !important;
                object-fit: cover !important;
            }
        }
    }
}