.news-home-container {
    background-color: #f3f6fb;
}

.news-home {
    display: grid;
    gap: 30px;
    padding-top: 50px;
    padding-bottom: 140px;
}

.news-home-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
    margin-right: -25px;

    img {
        height: 240px;
        width: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
    }

    // .block-posts .slick-list {
    //     margin: 0 -15px;
    // }
    
    // .slick-active {
    //     padding: 0 15px;
    // }
    .news-card {
        padding-right: 25px;
        position: relative;
    }

    .news-content {
        position: absolute;
        display: flex;
        border-radius: 0.5rem;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        min-height: 180px;
        padding: 30px;
        padding-bottom: 20px;
        bottom: -60px;
        background: #fff;
        box-shadow: 0 0.3125rem 3.5rem 0 rgba(34,29,31,.12);
        left: 30px;
    }
}