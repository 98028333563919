html {
    height: 100%;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

// #0a253b color web

#root {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    h1,h2,h3,h4,h5,h6 {
        margin: 0;
    }

    p {
        margin: 0;
        font-family: 'Inter';
    }
}

code {
    font-family: 'Inter';
}

.group-desktop {
    @media (max-width: 767px) {
        display: none;
    }
}

.burger-mobile {
    display: none; 
    @media (max-width: 767px) {
        display: block; 
    }
}

.web-load {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;

    span {
        font-family: 'Inter';
    }
}

.mantine-LoadingOverlay-root {
    position: fixed !important;
}

.site {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    backface-visibility: hidden;
}

.personal-body {
    height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .MuiContainer-root  {
        height: 100%;
    }
}

.MuiAlert-root { 
    z-index: 1301 !important;
}

.MuiAlert-standard {
    position: fixed;
    /* top: 0; */
    right: 20px;
    top: 25px;
    // transition: all 0.3s ease-in-out;
}

.grecaptcha-badge {
    display: none !important;
}