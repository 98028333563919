.grids-home {
    display: grid;
    gap: 30px;
    padding-top: 50px;
    padding-bottom: 30px;
}

.grids-card {
    width: 100%;
    height: 100%;
    position: relative;
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    border-radius: 0.5rem;
    padding: 80px 30px;
    overflow: hidden;
    transition: background-size 300ms ease;

    &:hover {
        background-size: 155%;
    }
}

.grids-card-title {
    color: #fff;
    z-index: 2;
    position: relative;
}